import * as React from 'react';
import {ProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import classNames from 'classnames';
import s from './ProductPageButtonsContainer.scss';
import {Cell} from '../Layouts/Cell/Cell';
import {AddToCartButton} from './AddToCartButton/AddToCartButton';
import {Mode, WishlistButton} from './WishlistButton/WishlistButton';
import _ from 'lodash';
import {BuyNowButton} from './BuyNowButton/BuyNowButton';
import {inStock} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';
import {ShowOnDesktopOnly} from '@wix/wixstores-client-common-components/dist/es/src/HOC/responsive/ShowOnDesktopOnly/ShowOnDesktopOnly';
import {SubscribeButton} from './SubscribeButton/SubscribeButton';
import {CashierExpressButton} from './CashierExpressButton/CashierExpressButton';
import {AddToCartState} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/AddToCartService/constants';
import {BackInStockButton} from './BackInStockButton/BackInStockButton';
import {PreOrderMessage} from './PreOrderMessage/PreOrderMessage';

export interface ProductPageButtonsContainerProps extends ProvidedGlobalProps, IProvidedTranslationProps {}

export enum DataHook {
  firstRow = 'buy-now-button-container-first-row',
  secondRow = 'buy-now-button-container',
  wishlistContainer = 'wishlist-container',
  cashierButtonContainer = 'cashier-button-container',
  backInStockErrorContainer = 'back-in-stock-error-container',
}

@withGlobalProps
@withTranslations('globals.texts')
export class ProductPageButtonsContainer extends React.Component<ProductPageButtonsContainerProps> {
  private readonly handleAddToCart = async () => {
    const {handleAddToCart} = this.props.globals;

    /* istanbul ignore if: todo(ariel): test ssr protection */
    _.isFunction(handleAddToCart) && (await handleAddToCart());
  };

  private readonly shouldShowSecondRowWithBuyNow = (disabled: boolean): boolean => {
    const {
      globals: {shouldShowAddToCartButton, shouldShowBuyNowButton, shouldShowSubscribeButton},
    } = this.props;
    return shouldShowAddToCartButton && shouldShowBuyNowButton && !disabled && !shouldShowSubscribeButton;
  };

  private readonly shouldShowSecondRowWithCashier = (): boolean => {
    const {
      globals: {
        dynamicPaymentsMethodsAmount,
        cashierExpressCheckoutWidgetProps: {demoMode},
      },
    } = this.props;
    return (
      !this.isDisabled() &&
      this.shouldPotentiallyShowSecondRowWithCashier() &&
      (dynamicPaymentsMethodsAmount !== undefined || demoMode)
    );
  };

  private readonly shouldPotentiallyShowSecondRowWithCashier = (): boolean => {
    const {
      globals: {
        shouldShowSubscribeButton,
        dynamicPaymentsMethodsAmount,
        shouldShowBuyNowButton,
        dynamicPaymentMethodsEnabled,
      },
    } = this.props;
    const hasDynamicPaymentMethod = dynamicPaymentsMethodsAmount > 0 || dynamicPaymentsMethodsAmount === undefined;
    return (
      !shouldShowSubscribeButton && hasDynamicPaymentMethod && shouldShowBuyNowButton && dynamicPaymentMethodsEnabled
    );
  };

  private readonly isDisabled = (): boolean => {
    return this.props.globals.addToCartState !== AddToCartState.ENABLED;
  };

  public render(): JSX.Element {
    const {
      globals: {
        addToCartState,
        selectedVariant,
        product,
        shouldShowWishlistButton,
        shouldShowAddToCartButton,
        shouldShowBuyNowButton,
        shouldShowSubscribeButton,
        isBackInStockEnabled,
        isPreOrderState,
      },
    } = this.props;
    const buttonContainerClass = classNames(s.productOptionsContainer, {
      [s.addToCartButtonContainer]: shouldShowWishlistButton,
    });
    const wishlistButtonContainer = classNames({
      [s.wishlistContainer]: !shouldShowAddToCartButton && !shouldShowBuyNowButton && !shouldShowSubscribeButton,
    });

    const secondRowClasses = classNames(s.productOptionsContainer, s.secondaryButton);
    const productInStock = inStock(product, selectedVariant);
    const disabled = addToCartState !== AddToCartState.ENABLED;
    const shouldShowBackInStockButton = isBackInStockEnabled && !productInStock && !isPreOrderState;

    const shouldPresentPreOrderMessage = isPreOrderState && !!product.inventory.preOrderInfoView.message;

    return (
      <>
        {shouldPresentPreOrderMessage ? <PreOrderMessage /> : null}
        <Cell className={buttonContainerClass} data-hook={DataHook.firstRow}>
          {shouldShowBackInStockButton && <BackInStockButton />}
          {!shouldShowBackInStockButton && shouldShowSubscribeButton && (
            <SubscribeButton productInStock={productInStock} />
          )}
          {!shouldShowBackInStockButton && !shouldShowSubscribeButton && shouldShowAddToCartButton && (
            <AddToCartButton productInStock={productInStock} handleAddToCart={this.handleAddToCart} />
          )}
          {!shouldShowSubscribeButton &&
            !shouldShowBackInStockButton &&
            shouldShowBuyNowButton &&
            !shouldShowAddToCartButton && <BuyNowButton productInStock={productInStock} />}
          {shouldShowWishlistButton && (
            <ShowOnDesktopOnly className={wishlistButtonContainer} data-hook={DataHook.wishlistContainer}>
              <WishlistButton mode={Mode.INLINE} />
            </ShowOnDesktopOnly>
          )}
        </Cell>
        {(this.shouldShowSecondRowWithBuyNow(disabled) || this.shouldPotentiallyShowSecondRowWithCashier()) && (
          <Cell data-hook={DataHook.secondRow} className={secondRowClasses}>
            {!this.shouldShowSecondRowWithCashier() && this.shouldShowSecondRowWithBuyNow(disabled) && (
              <BuyNowButton productInStock={productInStock} />
            )}
            {this.shouldPotentiallyShowSecondRowWithCashier() && (
              <div
                data-hook={DataHook.cashierButtonContainer}
                className={this.shouldShowSecondRowWithCashier() ? '' : s.invisible}>
                <CashierExpressButton />
              </div>
            )}
          </Cell>
        )}
      </>
    );
  }
}
