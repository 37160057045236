import React from 'react';
import classNames from 'classnames';
import {classes} from './BuyNowButton.st.css';
import * as productPageButtonsContainerStyles from '../ProductPageButtonsContainer.scss';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {Button, ButtonSize} from 'wix-ui-tpa';
import {ErrorNames, ModalState} from '../../../constants';
import {AddToCartState} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/AddToCartService/constants';

export interface BuyNowButtonProps extends ProvidedGlobalProps, IProvidedTranslationProps {
  productInStock: boolean;
}

export enum DataHook {
  buyNowButton = 'buy-now-button',
}

@withGlobalProps
@withTranslations('globals.texts')
export class BuyNowButton extends React.Component<BuyNowButtonProps> {
  public BuyNowButtonRef = React.createRef<HTMLButtonElement>();

  public componentDidUpdate(prevProps: Readonly<BuyNowButtonProps>): void {
    if (prevProps.globals.modalState === ModalState.OPEN && this.props.globals.modalState === ModalState.CLOSE) {
      this.focusButton();
    }
  }

  public focusButton = () => {
    this.BuyNowButtonRef.current.focus();
  };

  public render(): JSX.Element {
    const {
      t,
      productInStock,
      globals: {
        accessibilityEnabled,
        addToCartState,
        errors,
        handleBuyNow,
        product,
        selectedVariant,
        shouldShowAddToCartButton,
        shouldShowWishlistButton,
      },
    } = this.props;
    const {price} = selectedVariant || product;
    const disabled = addToCartState !== AddToCartState.ENABLED;
    let caption = t('BUY_NOW_BUTTON');

    if (disabled) {
      caption = price > 0 || !productInStock ? t('PRODUCT_OUT_OF_STOCK_BUTTON') : t('ADD_TO_CART_BUTTON_PRICE_ZERO');
    }

    if (errors.errorName === ErrorNames.StoreInfoError) {
      caption = 'try again'; //todo(ariell): change to translation
    }

    const buttonClasses = classNames(classes.buyNowButton, {
      [productPageButtonsContainerStyles.primaryButton]: shouldShowWishlistButton && !shouldShowAddToCartButton,
    });

    return (
      <Button
        type="button"
        ref={this.BuyNowButtonRef}
        onClick={() => handleBuyNow(accessibilityEnabled)}
        disabled={disabled}
        data-hook={DataHook.buyNowButton}
        className={buttonClasses}
        size={ButtonSize.large}
        fullWidth={!shouldShowWishlistButton || shouldShowAddToCartButton}>
        {caption}
      </Button>
    );
  }
}
